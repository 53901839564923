import React, { useEffect, useState } from "react";
import { constants } from "../../Constants";
import { OrderService } from "../../services/OrderService";
import { useDispatch, useSelector } from "react-redux";
import { PaymentService } from "../../services/PaymentService";
import { toast } from "react-toastify";
import { SERVER_URL } from "../../config/Config";
import { AuthService } from "../../services/AuthService";
import { isActiveCheckoutLoader } from "../../redux-service/actions/actions";

export default function RazorpayBtn() {
  const user = useSelector((state) => state.userReducer.userData);
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);
  const [btnText, setBtnText] = useState("");

  const cartData = useSelector((state) => state.cartReducer.cartData);
  const cartTotal =
    cartData && cartData
      ? cartData.reduce((total, value) => total + value.cart_product_total, 0)
      : 0;

  const authorization = AuthService.GetAuthToken();

  const dispatch = useDispatch();

  async function CreatePayment(order) {
    try {
      const {
        data: { razorpay_api_key },
      } = await new PaymentService().GetRazorpayApiKey();

      const options = {
        key: razorpay_api_key,
        amount: order.amount,
        currency: order.currency,
        name: `Cart Items (${cartData?.length || 1})`,
        description: "",
        image: "",
        order_id: order.id,
        callback_url: `${SERVER_URL}/api/v1/payment/razorpay/paymentverification?authorization=${authorization}`,
        prefill: {
          name: user.customer_name,
          email: user.customer_email,
          contact: user.customer_phone,
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#013d29",
        },
        modal: {
          ondismiss: function () {
            window.location.href = `${SERVER_URL}/api/v1/payment/razorpay/payment-canceled?orderId=${order.id}&authorization=${authorization}`;
          },
        },
        failure: function (response) {
          window.location.href = `${SERVER_URL}/api/v1/payment/razorpay/payment-failed?orderId=${order.id}&authorization=${authorization}`;
        },
      };

      // Razorpay popup open
      const razor = new window.Razorpay(options);
      razor.open();
    } catch (error) {
      console.error(`${constants.messages.errors.create} ${error.message}`);
    } finally {
      dispatch(isActiveCheckoutLoader(true));
    }
  }

  async function CreateOrderHandler() {
    setIsBtnDisabled(true);

    if (!user || !cartData) return;

    try {
      const payload = {
        customer_id: user._id,
        customer_cart_list: cartData,
        customer_payment: null,
        order_shipping: null,
        order_charges: null,
      };

      if (user) {
        payload.order_shipping = {
          status: "Pending",
          shipment_address: user?.customer_shipping_details || null,
        };
      }

      if (cartTotal) {
        payload.order_charges = {
          subTotal: cartTotal,
          total: cartTotal,
        };
      }

      payload.customer_payment = {
        type: "Razorpay",
        status: "Pending",
        response: null,
      };

      const response = await new OrderService().CreateOrder(payload);

      if (response.data.result) {
        console.log("response.data.result", response.data.result);

        setBtnText("Processing...");
        CreatePayment(response.data.result);
      }
    } catch (error) {
      console.error(`${constants.messages.errors.create} ${error.message}`);
    }
  }

  useEffect(() => {
    if (user?.customer_shipping_details) {
      setIsBtnDisabled(false);
    } else {
      setIsBtnDisabled(true);
    }
  }, [user?.customer_shipping_details]);

  return (
    <>
      <button
        type="button"
        className="btn btn-blue"
        onClick={CreateOrderHandler}
        disabled={isBtnDisabled}
      >
        Pay with Razorpay
      </button>
    </>
  );
}
