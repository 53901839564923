import React from "react";
import { IMAGE_BASE_URL, PUBLIC_IMAGE_PATH } from "../../config/Config";
import { constants } from "../../Constants";

export default function CartItmeSummaryCard({ value }) {
  return (
    <div className="cart-itme-summary-card">
      <div className="card-img">
        <img
          src={
            value.cart_product.product_image
              ? `${PUBLIC_IMAGE_PATH}/${value.cart_product.product_image}`
              : `${IMAGE_BASE_URL}/default-image-md.png`
          }
          className="img-fluid"
          alt="..."
        />
      </div>
      <div className="cart-text">
        <p>
          <b>{value.cart_product.product_name}</b>
        </p>
        <p>
          <b>
            <span className="text-blue">
              {constants.currency_symbol} {value.cart_product.product_price}{" "}
            </span>
            {/* <span className="text-danger">+ GST 18%</span> */}
          </b>
        </p>
        <p>
          <b>Qty:</b> {value.cart_product_qty}
        </p>
        <p>
          <b>Total:</b> {constants.currency_symbol} {value.cart_product_total}
        </p>
      </div>
    </div>
  );
}
