import React, { useEffect, useState } from "react";
import { constants } from "../../Constants";
import AddToCartBtn from "../button/AddToCartBtn";
import { IMAGE_BASE_URL, PUBLIC_IMAGE_PATH } from "../../config/Config";
import { useNavigate } from "react-router-dom";
import ProductSkeleton from "../skeleton/ProductSkeleton";

export default function ProductCart1({ value }) {
  const [product, setProduct] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setProduct(value);
    }, 500);
  }, []);

  return (
    <>
      {product ? (
        <div className="card product-card-1 card-dark">
          <div
            className="card-img"
            onClick={() =>
              navigate(`/products/${value?.product_slug}/${value?._id}`)
            }
          >
            <img
              src={
                value?.product_image
                  ? `${PUBLIC_IMAGE_PATH}/${value?.product_image}`
                  : `${IMAGE_BASE_URL}/default-image-md.png`
              }
              className="img-fluid"
              alt="..."
            />
          </div>
          <div className="card-body">
            <div className="product-card-text">
              <h5 className="card-title">{value?.product_name || "N/A"}</h5>
              <p className="card-price mb-0">
                <span
                  style={{
                    color: "var(--light-text-color)",
                    fontWeight: "var(--fw-medium)",
                  }}
                >
                  Price:
                </span>{" "}
                {constants.currency_symbol} {value?.product_price || "N/A"}/-{" "}
                <span className="text-danger">
                  + GST 18%
                </span>
              </p>
            </div>
            {value?.product_stock_qty > 0 && (
              <AddToCartBtn
                type="icon"
                value={{
                  cart_product_id: value._id,
                  qty: 1,
                }}
              />
            )}
          </div>
        </div>
      ) : (
        <ProductSkeleton />
      )}
    </>
  );
}
