import React, { useEffect, useState } from "react";
import { IMAGE_BASE_URL, PUBLIC_IMAGE_PATH } from "../../config/Config";
import QtyPlusAndMinus from "../../component/button/QtyPlusAndMinus";
import AddToCartBtn from "../../component/button/AddToCartBtn";
import { constants } from "../../Constants";
import { useParams } from "react-router-dom";
import { ProductService } from "../../services/ProductService";

export default function ProductSingle() {
  const params = useParams();
  const productId = params.id;

  const [productData, setProductData] = useState(null);
  const [mainProductImage, setMainProductImage] = useState(null);

  async function fetchProductData() {
    try {
      const response = await new ProductService().GetProductDetails(productId);
      setProductData(response.data.result);
      if (response.data.result.product_image) {
        setMainProductImage(response.data.result.product_image);
      }
    } catch (error) {
      console.error(`${constants.messages.errors.read} ${error.message}`);
    }
  }

  useEffect(() => {
    fetchProductData();
  }, [productId]);

  return (
    <div className="product-single">
      <div className="container">
        <div className="row mb-5 gx-lg-5">
          <div className="col-lg-6 mb-4 mb-lg-0">
            <div className="product-single-image">
              <img
                src={
                  mainProductImage
                    ? `${PUBLIC_IMAGE_PATH}/${mainProductImage}`
                    : `${IMAGE_BASE_URL}/default-image-md.png`
                }
                className="img-fluid"
                alt="..."
              />
              <div className="product-single-image-sm">
                {productData?.product_image && (
                  <div
                    onClick={() =>
                      setMainProductImage(productData?.product_image)
                    }
                  >
                    <img
                      src={
                        productData?.product_image
                          ? `${PUBLIC_IMAGE_PATH}/${productData?.product_image}`
                          : `${IMAGE_BASE_URL}/default-image-md.png`
                      }
                      className="img-fluid"
                      alt="..."
                    />
                  </div>
                )}
                {productData?.product_gallery &&
                  productData?.product_gallery.length > 0 &&
                  productData?.product_gallery.map((gallery) => (
                    <div onClick={() => setMainProductImage(gallery)}>
                      <img
                        src={
                          productData?.product_image
                            ? `${PUBLIC_IMAGE_PATH}/${gallery}`
                            : `${IMAGE_BASE_URL}/potter-pump-product.jpg`
                        }
                        className="img-fluid"
                        alt="..."
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="product-single-content">
              <h1 className="heading-2 mb-2 mb-lg-4">
                {productData?.product_name}
              </h1>
              <p className="mb-3 mb-lg-4">{productData?.product_description}</p>
              <p className="price mb-3 mb-lg-4">
                {constants.currency_symbol}{" "}
                {productData?.product_price || "N/A"}/-{" "}
                <span className="text-danger">
                  + GST 18%
                </span>
              </p>
              <p className="mb-3 mb-lg-4">
                <span>Stock Qty: </span>
                {productData?.product_stock_qty || 0}
              </p>
              {/* <p className="d-flex gap-2 mb-3 mb-lg-4">
                <span>Quantity: </span>
                {productData?.product_stock_qty > 0 ? (
                  <QtyPlusAndMinus
                    value={{
                      cartQty: value.cart_product_qty,
                      cartId: value._id,
                    }}
                  />
                ) : (
                  "Out of Stock"
                )}
              </p> */}
              {/* <p className="mb-3 mb-lg-4">
                <span>Subtotal: </span>
                {constants.currency_symbol} 3450
              </p> */}
              {productData?.product_stock_qty > 0 ? (
                <AddToCartBtn
                  value={{
                    cart_product_id: productData._id,
                    qty: 1,
                  }}
                />
              ) : (
                <button className="btn btn-blue" disabled>
                  Out of Stock
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-4">
            <h2 className="h4">Additional Information</h2>
          </div>
          <div className="col-12">
            <div className="table-responsive additional-information-table scrollbar-sm scrollbar-gray">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    {productData?.product_specifications &&
                    productData?.product_specifications.length > 0 ? (
                      productData?.product_specifications.map(
                        (label, labelIndex) => (
                          <th
                            key={`product_specifications_label_${labelIndex}`}
                          >
                            {label.label}
                          </th>
                        )
                      )
                    ) : (
                      <th colSpan={25} className="py-5 text-center">
                        Table Data Not Found.
                      </th>
                    )}
                  </tr>
                </thead>
                {productData?.product_specifications &&
                  productData?.product_specifications.length > 0 && (
                    <tbody>
                      <tr>
                        {productData?.product_specifications.map(
                          (value, valueIndex) => (
                            <td
                              key={`product_specifications_value_${valueIndex}`}
                            >
                              {value.value}
                            </td>
                          )
                        )}
                      </tr>
                    </tbody>
                  )}
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
