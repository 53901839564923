import React, { useEffect, useLayoutEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./pages/Home";
import CustomWrapper from "./wrapper/CustomWrapper";
import ProductSingle from "./pages/products/ProductSingle";
import AboutUs from "./pages/AboutUs";
import Products from "./pages/products/Products";
import ContactUs from "./pages/ContactUs";
import Login from "./pages/user/Login";
import UserWrapper from "./wrapper/UserWrapper";
import ForgetPassword from "./pages/user/ForgetPassword";
import ResetPassword from "./pages/user/ResetPassword";
import Register from "./pages/user/Register";
import { AuthService } from "./services/AuthService";
import { useDispatch } from "react-redux";
import {
  getUserCartData,
  setUserDataAction,
} from "./redux-service/actions/actions";
import runInterceptor from "./interceptor";
import PageNotFound from "./pages/PageNotFound";
import Checkout from "./pages/checkout/Checkout";
import Order from "./pages/orders/Order";
import PaymentSuccessMessage from "./component/message-boxs/PaymentSuccessMessage";
import PaymentFailedMessage from "./component/message-boxs/PaymentFailedMessage";
import PrivacyAndTermsAndCondition from "./pages/PrivacyAndTermsAndCondition";

export default function RouterRoot() {
  const [loading, setLoading] = useState(true);

  const params = useLocation();
  const pathName = params.pathname;

  const userAuthData = AuthService.GetUserAuth();
  const userID = userAuthData && userAuthData.user._id;

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        if (userID) {
          dispatch(setUserDataAction(userID));
          dispatch(getUserCartData(userID));
        } else {
          console.log("User ID not available. Skipping API calls.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userID, dispatch, pathName]);

  useLayoutEffect(() => {
    runInterceptor();
  }, []);

  return (
    <Routes>
      <Route
        path="/"
        element={
          <CustomWrapper
            cmp={Home}
            meta={{
              title: "Potter Pumps - Home",
            }}
          />
        }
      />
      <Route
        path="/about-us"
        element={
          <CustomWrapper
            cmp={AboutUs}
            meta={{
              title: "About Us - Potter Pumps",
            }}
          />
        }
      />
      <Route
        path="/privacy-policy"
        element={
          <CustomWrapper
            cmp={PrivacyAndTermsAndCondition}
            meta={{
              title: "privacy policy and T&C - Potter Pumps",
            }}
          />
        }
      />
      <Route
        path="/products"
        element={
          <CustomWrapper
            cmp={Products}
            meta={{
              title: "Products - Potter Pumps",
            }}
          />
        }
      />
      <Route
        path="/contact-us"
        element={
          <CustomWrapper
            cmp={ContactUs}
            meta={{
              title: "Contact Us - Potter Pumps",
            }}
          />
        }
      />
      <Route
        path="/products/:name/:id"
        element={
          <CustomWrapper
            cmp={ProductSingle}
            meta={{
              title: "Potter Pumps",
            }}
          />
        }
      />

      {/* user pages */}

      <Route
        path="/user/login"
        element={
          <UserWrapper
            cmp={Login}
            meta={{
              title: "Login - User - Potter Pumps",
            }}
            authRequired={2}
          />
        }
      />
      <Route
        path="/user/register"
        element={
          <UserWrapper
            cmp={Register}
            meta={{
              title: "Register - User - Potter Pumps",
            }}
            authRequired={2}
          />
        }
      />
      <Route
        path="/user/profile/update"
        element={
          <UserWrapper
            cmp={Register}
            meta={{
              title: "Profile - User - Potter Pumps",
            }}
            authRequired={1}
          />
        }
      />
      <Route
        path="/user/checkout"
        element={
          <CustomWrapper
            cmp={Checkout}
            meta={{
              title: "Checkout - User - Potter Pumps",
            }}
            authRequired={1}
          />
        }
      />
      <Route
        path="/user/orders"
        element={
          <CustomWrapper
            cmp={Order}
            meta={{
              title: "Your Orders - User - Potter Pumps",
            }}
            authRequired={1}
          />
        }
      />
      <Route
        path="/payment-success"
        element={
          <UserWrapper
            cmp={PaymentSuccessMessage}
            meta={{
              title: "Payment Successfully - Checkout - Potter Pumps",
            }}
            authRequired={1}
          />
        }
      />
      <Route
        path="/payment-fail"
        element={
          <UserWrapper
            cmp={PaymentFailedMessage}
            meta={{
              title: "Payment Fail - Checkout - Potter Pumps",
            }}
            authRequired={1}
          />
        }
      />
      <Route
        path="/user/forget-password"
        element={
          <UserWrapper
            cmp={ForgetPassword}
            meta={{
              title: "Forget Password - User - Potter Pumps",
            }}
            authRequired={2}
          />
        }
      />
      <Route
        path="*"
        element={
          <CustomWrapper
            cmp={PageNotFound}
            meta={{
              title: "404 page not found - Potter Pumps",
            }}
          />
        }
      />
    </Routes>
  );
}
