import React from "react";
import { useSelector } from "react-redux";
import CartItmeSummaryCard from "../../component/card/CartItmeSummaryCard";
import { AuthService } from "../../services/AuthService";
import { Navigate } from "react-router-dom";
import { constants } from "../../Constants";

export default function OrderSummary() {
  const isAuthenticated = AuthService.isAuthenticated();

  const cartData = useSelector((state) => state.cartReducer.cartData);

  if (!isAuthenticated) {
    return <Navigate to="/user/login" />;
  }

  return (
    <>
      <div className="mb-4 mb-lg-5">
        <div className="grid cart-itme-summary">
          {cartData &&
            cartData.length > 0 &&
            cartData.map((value, index) => (
              <>
                <div className="grid-item">
                  <CartItmeSummaryCard value={value} />
                </div>
              </>
            ))}
        </div>
        <ul className="summary-total">
          <li>
            <b>Sub Total: </b>
            {constants.currency_symbol}
            {cartData &&
              cartData.length > 0 &&
              cartData.reduce(
                (total, value) => total + value.cart_product_total,
                0
              )}
          </li>
          <li> <b>GST:</b> + () 18% </li>
          <li>
            <b>Total:</b>
            <span style={{ fontSize: "22px", fontWeight: "600" }}>
              {constants.currency_symbol}{" "}
              {cartData &&
                cartData.length > 0 &&
                cartData.reduce( (total, value) =>
                   total + value.cart_product_total,
                  0
                )}
            </span>
          </li>
        </ul>
      </div>
    </>
  );
}
