import React, { useEffect } from "react";
import Badge from "@mui/material/Badge";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserCartData,
  headerCartMenuToggle,
} from "../../redux-service/actions/actions";
import { constants } from "../../Constants";
import QtyPlusAndMinus from "../button/QtyPlusAndMinus";
import RemoveToCartBtn from "../button/RemoveToCartBtn";
import { IMAGE_BASE_URL, PUBLIC_IMAGE_PATH } from "../../config/Config";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import { AuthService } from "../../services/AuthService";

export default function CartMenu() {
  const navigate = useNavigate();

  const IsCartMenu = useSelector(
    (state) => state.headerReducer.cart_menu_toggle
  );
  const user = AuthService.GetUserAuth();
  const userID = user?.user?._id;

  const cartData = useSelector((state) => state.cartReducer.cartData);

  const dispatch = useDispatch();

  function cartMenuOpenHandler() {
    if (!userID) {
      navigate("/user/login");
      return;
    }

    dispatch(headerCartMenuToggle());
    dispatch(getUserCartData(userID));
  }

  useEffect(() => {
    document.body.style.overflow = IsCartMenu ? "hidden" : "unset";
  }, [IsCartMenu]);

  return (
    <>
      <li
        className="cart-menu"
        onClick={cartMenuOpenHandler}
        style={{ cursor: "pointer" }}
      >
        {userID ? (
          <Badge badgeContent={cartData?.length || 0} color="primary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              width="32"
              height="32"
              fill="currentColor"
              id="cart-icon"
              aria-haspopup="true"
            >
              <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
            </svg>
          </Badge>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            width="32"
            height="32"
            fill="currentColor"
            id="cart-icon"
            aria-haspopup="true"
          >
            <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
          </svg>
        )}

        <div>
          <span>Your Cart</span>
          <b>
            {constants.currency_symbol}{" "}
            {cartData && cartData.length > 0
              ? cartData.reduce(
                (total, value) => total + value.cart_product_total,
                0
              )
              : 0}
          </b>
        </div>
      </li>
      <div
        className={`cart-menu-dropdown ${IsCartMenu ? "active" : ""
          } scrollbar-sm scrollbar-gray`}
      >
        <div className="cart-menu-head">
          <button
            type="button"
            className="btn btn-blue btn-icon"
            onClick={cartMenuOpenHandler}
          >
            <i className="fa-solid fa-xmark"></i>
          </button>
          <h2 className="h3">Your Cart</h2>
        </div>
        {userID ? (
          <>
            <div className="cart-menu-body">
              {cartData && cartData?.length > 0 ? (
                <div className="table-responsive">
                  <table className="table table-striped cart-menu-table">
                    <thead>
                      <tr>
                        <th>Item</th>
                        <th>QTY</th>
                        <th>Sub Total</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cartData &&
                        cartData?.length > 0 &&
                        cartData.map((value, index) => (
                          <tr>
                            <td>
                              <div
                                className="cart-menu-item"
                                onClick={() => {
                                  navigate(
                                    `/products/${value.cart_product?.product_slug}/${value.cart_product?._id}`
                                  );
                                  cartMenuOpenHandler();
                                }}
                              >
                                <div className="cart-menu-item-img">
                                  <img
                                    src={
                                      value.cart_product.product_image
                                        ? `${PUBLIC_IMAGE_PATH}/${value.cart_product.product_image}`
                                        : `${IMAGE_BASE_URL}/default-image-md.png`
                                    }
                                    className="img-fluid"
                                    alt="..."
                                  />
                                </div>
                                <div className="cart-menu-item-content">
                                  <p>
                                    <b>{value.cart_product.product_name}</b>
                                  </p>
                                  <p>
                                    <b>
                                      {constants.currency_symbol}{" "}
                                      {value.cart_product.product_price}{" "}
                                    </b>
                                  </p>
                                </div>
                              </div>
                            </td>
                            <td>
                              <QtyPlusAndMinus
                                value={{
                                  cartQty: value.cart_product_qty,
                                  cartId: value._id,
                                  stock_qty:
                                    value.cart_product.product_stock_qty,
                                }}
                              />
                            </td>
                            <td>
                              {constants.currency_symbol}{" "}
                              {value.cart_product.product_price *
                                value.cart_product_qty}
                            </td>
                            <td>
                              <RemoveToCartBtn
                                value={{
                                  cartId: value._id,
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                    <tfoot>
                    <tr>
                        <th colSpan={2}>GST {" - "} 
                        <span className="text-danger">18%</span>
                        </th>
                        <th colSpan={2}>
                          +{ (cartData.reduce((total, value) => total + value.cart_product_total, 0)).toFixed(2) - (cartData.reduce((total, value) => total + value.cart_product_total, 0) / 1.18 )}
                          </th>
                      </tr>
                      <tr>
                        <th colSpan={2}>Total</th>
                        <th colSpan={2}>
                          {constants.currency_symbol}{" "}
                          {(cartData.reduce((total, value) => total + value.cart_product_total, 0)).toFixed(2)}
                          </th>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              ) : (
                <div className="d-flex justify-content-center py-5">
                  <img
                    src={`${IMAGE_BASE_URL}/cart-not-found.png`}
                    alt=""
                    className="img-fluid"
                  />
                </div>
              )}
            </div>
            {cartData && cartData?.length > 0 && (
              <>
                <div className="cart-menu-foot">
                  <NavLink
                    to="/user/checkout"
                    className="btn btn-black"
                    onClick={cartMenuOpenHandler}
                  >
                    Checkout
                  </NavLink>
                </div>
              </>
            )}
          </>
        ) : (
          <div className="d-flex justify-content-center py-5 my-5">
            <NavLink to="/user/login" className="btn btn-blue my-5">
              Please log in to continue.
            </NavLink>
          </div>
        )}
      </div>
      <div
        className={`cart-menu-dropdown-overlay ${IsCartMenu ? "active" : ""}`}
        onClick={cartMenuOpenHandler}
      ></div>
    </>
  );
}
