import React from "react";
import { NavLink } from "react-router-dom";

export default function PrivacyAndTermsAndCondition() {
    return (
        <>
            <section className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10 col-md-12 mt-5 pt-5">
                        <div className="row text-center">
                            <h1 className="mb-4" style={{ fontWeight: "bold" }}>Our Policies</h1>
                        </div>
                        <div className="policy-container p-4 rounded" >
                            <div className="row mb-4">
                                <h2>Privacy Policy</h2>
                                <p>
                                    At <b>potterpumps</b>, operated by <b>Bhagwati Electric</b>, we value your privacy. We only collect personal
                                    information such as your <b>name, address, email</b>, and <b>phone number</b> to process orders and provide
                                    support. Your data is <b>never shared</b> with third parties without your consent. By using our
                                    website, you agree to our <b>privacy policy</b>.
                                </p>
                            </div>
                            <div className="row mb-4">
                                <h2>Terms and Conditions</h2>
                                <div className="col-lg-12 col-md-12">
                                    <p> By purchasing from <b>potterpumps</b>, you agree to the following <b>terms and conditions</b>:</p>
                                    <ul>
                                        <li><b>1-Year Warranty:</b> All products come with a warranty. However, we do not offer piece-to-piece
                                            replacement of products under warranty.</li>
                                        <li><b>GST Charges:</b> GST charges are applicable on all products.</li>
                                        <li><b>No Returns:</b> Returns are not accepted once the product is sold.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row mb-4">
                                <h2>Cancellation and Refund Policy</h2>
                                <p>
                                    We do not accept <b>returns</b> or <b>cancellations</b> once the product is shipped. Refunds will not be
                                    entertained unless the product is found to be <b>defective upon delivery</b>. In such cases, contact
                                    our <b>support team</b> within 48 hours of receiving the product.
                                </p>
                            </div>
                            <div className="row mb-5">
                                <h2>Shipping and Delivery</h2>
                                <p>
                                    <b>Shipping charges</b> and <b>delivery timelines</b> will vary depending on your location. We ensure that
                                    products are shipped securely and arrive in excellent condition. <b>Bhagwati Electric</b> is not
                                    responsible for delays caused by external shipping providers.
                                </p>
                            </div>
                            <div className="text-center mt-4">
                                <NavLink to="/" className="btn btn-black mt-2 animate-grow">
                                    Go Back to Home
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
